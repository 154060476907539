import { Link } from "react-router-dom";

import dbMenu from "../data/dbMenu";


const NavsLink = ({ extraClassName }) => {
    const handleDropdownStatus = (e) => {
        let clickedItem = e.currentTarget.parentNode;
        clickedItem.querySelector(".dropdown-list").classList.toggle("show");
    };
    return (
        <ul className={`main-menu__list ${extraClassName}`}>
            {
                dbMenu.dbMenu.map((item, index) => {
                    return(
                        <li key={index}>
                            <Link to={item.link}>{item.name}</Link>
                        </li>
                    )
                })
            }
        </ul>
    );
}

export default NavsLink;